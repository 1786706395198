import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import styles from "./services.module.css";

export default function Services() {
  const { site } = useStaticQuery(query);
  return (
    <Layout
      seoTitle="Services offered"
      seoDescription="Details of services related to auditing, taxation, consultancy are offered"
    >
      <div className={styles.container}>
        <h1 className={styles.pageTitle}>Services Offered</h1>
        <div className={styles.services}>
          {site.siteMetadata.services.map((service, index) => {
            return (
              <div key={index} className={styles.service}>
                <ul className={styles.serviceName}>{service.name}</ul>
                {service.subServices.map((subService, subIndex) => {
                  return (
                    <li key={subIndex} className={styles.subService}>
                      {subService}
                    </li>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

const query = graphql`
  query Services {
    site {
      siteMetadata {
        services {
          name
          subServices
        }
      }
    }
  }
`;
